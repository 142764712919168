/**
 * @class NotImplemented
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from './Box';

export default class NotImplemented extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
  };

  render() {
    const { title, message } = this.props;
    const defaultTile = 'Not Implemented';
    const defaultMessage = 'Feature Not Implemented Yet. We will add it soon';

    return (
      <Box header={title || defaultTile} body={<Fragment>{message || defaultMessage}</Fragment>} />
    );
  }
}
