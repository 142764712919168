/**
 * @class UserMenu
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import incognitoImg from '../images/incognito-avatar.png';

export default class UserMenu extends Component {
  static propTypes = {
    onSingOut: PropTypes.func.isRequired
  };

  state = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (!e.target.id) {
      this.setState({ isOpen: false });
    }
  };

  toggleOpenMenu = e => {
    e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isOpen } = this.state;
    const { onSingOut } = this.props;
    const defaultSingOutItemTitle = 'Sign out';

    return (
      <div id="userMenu" className={styles.headerUserMenu}>
        <div
          id="userMenuDropDown"
          className={styles.headerUserMenuDropDown}
          onClick={this.toggleOpenMenu}
        >
          <img id="userMenuAvatar" className={styles.headerUserMenuAvatar} src={incognitoImg} />
        </div>
        {isOpen ? (
          <ul
            id="userMenuList"
            className={`${styles.headerMenuMoreList} ${styles.headerUserMenuList}`}
          >
            <li id="userMenuItem" className={styles.headerMenuItem} onClick={onSingOut}>
              {defaultSingOutItemTitle}
            </li>
          </ul>
        ) : null}
      </div>
    );
  }
}
