/**
 * @class Header
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';
import Menu from './Menu';
import UserMenu from './UserMenu';
import styles from './styles.css';

export default class Header extends Component {
  static propTypes = {
    logoHref: PropTypes.string,
    logoImg: PropTypes.string,
    title: PropTypes.string,

    menuItems: PropTypes.array,
    isActiveMenuItem: PropTypes.func,
    onMenuItemClick: PropTypes.func,

    isLogin: PropTypes.bool,
    onSingOut: PropTypes.func,
    loginEl: PropTypes.element,
    userName: PropTypes.string
  };

  render() {
    const {
      logoHref,
      logoImg,
      title,
      menuItems,
      isActiveMenuItem,
      onMenuItemClick,
      isLogin,
      onSingOut,
      loginEl,
      userName
    } = this.props;

    return (
      <div className={styles.header}>
        <Logo logoHref={logoHref} logoImg={logoImg} userName={userName} />
        <div className={styles.headerBody}>
          {title ? <div className={styles.headerTitle}>{title}</div> : null}
          {!title && menuItems && menuItems.length ? (
            <Menu
              items={menuItems}
              isActiveMenuItem={isActiveMenuItem}
              onItemClick={onMenuItemClick}
            />
          ) : null}
          {isLogin ? <UserMenu onSingOut={onSingOut} /> : null}
          {!isLogin && loginEl ? (
            <div className={styles.headerUserMenuLoginEl}>{loginEl}</div>
          ) : null}
        </div>
      </div>
    );
  }
}
