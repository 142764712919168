/**
 * @class MenuMoreBtn
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

export default class MenuMoreBtn extends Component {
  static propTypes = {
    currentMenuWidth: PropTypes.number.isRequired,
    moreItems: PropTypes.array,
    isActiveMenuItem: PropTypes.func,
    getMenuItemClassName: PropTypes.func.isRequired,
    onItemClick: PropTypes.func
  };

  state = {
    isOpen: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (!e.target.id) {
      this.setState({ isOpen: false });
    }
  };

  getNavDropDownClassName = () => {
    const { moreItems, isActiveMenuItem } = this.props;
    let className = `${styles.headerMenuItem} ${styles.headerMenuMore}`;

    if (typeof isActiveMenuItem === 'function' && moreItems.some(item => isActiveMenuItem(item))) {
      className += ` ${styles.headerMenuItemActive}`;
    }
    if (moreItems.length) {
      className += ` ${styles.headerMenuItemShown}`;
    }
    return className;
  };

  onMoreBtnClick = e => {
    e.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isOpen } = this.state;
    const { moreItems, getMenuItemClassName, onItemClick, currentMenuWidth } = this.props;
    const defaultMoreTitle = "more";

    return (
      <div
        id="more-item"
        className={this.getNavDropDownClassName()}
        style={{ left: currentMenuWidth }}
      >
        <a id="more-link" onClick={this.onMoreBtnClick}>
          {defaultMoreTitle}
        </a>
        {isOpen ? (
          <ul id="more-list" className={styles.headerMenuMoreList}>
            {moreItems.map((item, index) => (
              <li
                id="more-list-item"
                key={index}
                className={getMenuItemClassName(item)}
                onClick={() => onItemClick(item)}
              >
                {item && item.title}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
}
