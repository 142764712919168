/**
 * @class Logo
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import defaultLogoImg from '../images/logo.png';

export default class Logo extends Component {
  static propTypes = {
    logoHref: PropTypes.string,
    logoImg: PropTypes.string,
    userName: PropTypes.string
  };

  render() {
    const { logoHref, logoImg, userName } = this.props;

    return (
      <div className={styles.headerBrand}>
        <a href={logoHref || '/'} className={userName ? styles.logoWithUserName : ''}>
          <img src={logoImg || defaultLogoImg} alt="Logo" />
        </a>
        {userName ? <span className={styles.userName}>{userName}</span> : null}
      </div>
    );
  }
}
