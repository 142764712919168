/**
 * @class Menu
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuMoreBtn from './MenuMoreBtn';
import styles from './styles.css';
import 'font-awesome/css/font-awesome.css';

export default class Menu extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    isActiveMenuItem: PropTypes.func,
    onItemClick: PropTypes.func
  };

  state = {
    items: this.props.items,
    moreItems: [],
    currentMenuWidth: 0
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateMenu);
    this.updateMenu();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMenu);
  }

  updateMenu = () => {
    if (this.nav) {
      const maxWidth = this.nav.clientWidth - 40;
      const items = this.nav.children;
      const menuItems = [...this.state.items];
      const moreItems = [];
      let currentMenuWidth = 0;
      let isEnd = false;

      for (let i = 0; i < items.length; i++) {
        if (currentMenuWidth + items[i].offsetWidth <= maxWidth && !isEnd) {
          currentMenuWidth += items[i].offsetWidth;
          menuItems[i].visible = true;
        } else {
          isEnd = true;
          menuItems[i].visible = false;
          moreItems.push(menuItems[i]);
        }
      }
      this.setState({ menuItems, moreItems, currentMenuWidth });
    }
  };

  getItemClassName = item => {
    const { isActiveMenuItem } = this.props;
    let className = styles.headerMenuItem;
    if (typeof isActiveMenuItem === 'function' && isActiveMenuItem(item)) {
      className += ` ${styles.headerMenuItemActive}`;
    }
    if (item.visible) {
      className += ` ${styles.headerMenuItemShown}`;
    }
    return className;
  };

  onItemClick = item => {
    const { onItemClick } = this.props;
    if (typeof onItemClick === 'function') {
      onItemClick(item);
    }
  };

  render() {
    const { items, currentMenuWidth, moreItems } = this.state;
    const { isActiveMenuItem } = this.props;

    return (
      <div className={styles.headerWrapMenu}>
        <div
          ref={nav => {
            this.nav = nav;
          }}
          className={styles.headerMenu}
        >
          {items.map((item, index) => (
            <div
              id={index}
              key={index}
              className={this.getItemClassName(item)}
              onClick={() => this.onItemClick(item)}
            >
              <i className={`fa fa-${item.icon} fa-lg`} />
              <div>{item.title}</div>
            </div>
          ))}
        </div>
        <MenuMoreBtn
          currentMenuWidth={currentMenuWidth}
          moreItems={moreItems}
          isActiveMenuItem={isActiveMenuItem}
          getMenuItemClassName={this.getItemClassName}
          onItemClick={this.onItemClick}
        />
      </div>
    );
  }
}
