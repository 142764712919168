/**
 * @class NoMatch
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from './Box';
import styles from './styles.css';

export default class NoMatch extends Component {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    pathname: PropTypes.string
  };

  render() {
    const { title, message, pathname } = this.props;
    const defaultTitle = 'Page not found';
    const defaultMessage = 'No match for ';
    const defaultPathName = 'this path name';

    return (
      <Box
        header={title || defaultTitle}
        body={
          <Fragment>
            {message || (
              <Fragment>
                {defaultMessage}
                {pathname ? <span className={styles.code}>{pathname}</span> : defaultPathName}
              </Fragment>
            )}
          </Fragment>
        }
      />
    );
  }
}
