/**
 * @class Box
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

export default class Box extends Component {
  static propTypes = {
    header: PropTypes.string,
    body: PropTypes.element
  };

  render() {
    const { header, body } = this.props;

    return (
      <div className={`box-wrap ${styles.boxWrap}`}>
        <div className={`box ${styles.box}`}>
          <div className={`box-header ${styles.boxHeader}`}>{header}</div>
          <div className={`box-body ${styles.boxBody}`}>{body}</div>
        </div>
      </div>
    );
  }
}
